import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Portfolio from '../views/Portfolio.vue'
import PortfolioInner from '../views/PortfolioInner.vue'
import Archive from '../views/Archive.vue'
import News from '../views/News.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/portfolio',
    component: Portfolio
  },
  {
    path: '/archive',
    component: Archive
  },
  {
    path: '/news',
    component: News
  },
  { path: '/portfolio/:slug', component: PortfolioInner, props: true },
]

const router = new VueRouter({
  mode: 'history',
  routes,
  linkActiveClass: 'selected',
  // eslint-disable-next-line no-unused-vars
  scrollBehavior (to, from, savedPosition) { return { x: 0, y: 0 } }
})

export default router
