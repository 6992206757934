import spanishTexts from './es';
import englishTexts from './en';
import Vue from 'vue'
import VueI18n from 'vue-i18n'

// Ready translated locale messages
const messages = {
  es: spanishTexts,
  en: englishTexts
}

Vue.use(VueI18n)

// Create VueI18n instance with options
export default new VueI18n({
  locale: 'es', // set locale
  messages, // set locale messages
})