<template>
<footer class="contact pt-40">
    <a href="#" class="lang-btn" @click.prevent="changeLang">{{ $t("langBtn") }}</a>
    <p class="contactRow">
        <a href="mailto:contact@ivanmarin.mx">contact@ivanmarin.mx</a>
        <strong>+51 (443)314 5834</strong>
        <span class="social">
            <a target="_blank" href="https://www.facebook.com/arquitecturaim">FB</a>&nbsp;
            <a target="_blank" href="https://www.instagram.com/arquitecturaim/">IG</a>
        </span>
    </p>
    <p class="byText">&copy;Copyright IVÁN MARÍN ARQUITECTURA BY Doho_CONSTRUCTIVO MRL CDMX LAX</p>
</footer>
</template>

<script>
export default {
    data() {
        return {
            lang: "es"
        }
    },
    methods: {
        changeLang(){
            this.lang = this.lang == "es" ? "en" : "es";
            this.$i18n.locale = this.lang;
        }
    },
    computed: {
    }
}
</script>

<style lang="scss" scoped>
@import '~foundation-sites/scss/util/_util';
.byText {
    font-weight: bold;
    font-size: 12px;
    margin: 0;
}
.copyRText{
    font-size: 9px;
}
footer.contact {
    margin-top: auto;
    padding-bottom: 20px;
    bottom: 0;

    &, a{
        font-size: 16px;
        color: #000;
    }
    .contactRow {
        & > * {
            display: inline-block;
            margin-right: 10px;
        }
        .social a {
            display: inline-block;
        }
    }
    .lang-btn {
        font-weight: bold;
    }
}

@include breakpoint(large) {
    footer.contact {
        // position: fixed;
        padding-bottom: 30px;
        // padding-right: 75px;
        // width: 97.5%;
    }
}

</style>