<template>
<div class="grid-container fluid main-container">
  <div class="horizonte"></div>
  <section class="grid-x grid-margin-x main-content">
    <div class="cell large-4">
        <h2>{{ $t('newsTitle') }}</h2>
        <footer-component class="show-for-large" />
    </div>
    <div class="cell large-6">
        <ul>
            <li v-for="(anchor, i) in links" :key="i">
                <a :class="{over: isHover}" :href="anchor.url" target="_blank">{{ anchor.text }}</a>
            </li>
        </ul>
    </div>
  </section>
  <footer-component class="cell hide-for-large" />
</div>
</template>

<script>
import FooterComponent from '../components/FooterComponent.vue'

export default {
  name: 'News',
  components: {
    FooterComponent
  },
  data() {
      return {
          isHover: false,
          links: [
            {
                url: "https://mexicodesign.com/proyecto-de-ivan-marin-arquitectura-y-doho-constructivo-es-galardonado/",
                text: "2020/ IMA Mexicodesign"
            },
            {
                url: "https://awards.re-thinkingthefuture.com/acda2020-runners-up/wol-center-by-ivan-marin-arquitectura/",
                text: "2020/ IMA Re-thinking the future"
            },
            {
                url: "https://www.archdaily.mx/mx/office/ivan-marin",
                text: "2020/ IMA Archdaily"
            },
            {
                url: "https://www.adncultura.org/designan-galardonados-en-el-architecture-construction-design-award-2020-de-rethinking-future",
                text: "2020/ IMA Adncultura"
            },
            {
                url: "https://a.com.mx/premio-architizer/",
                text: "2020/ IMA a! diseño"
            },
            {
                url: "https://www.arquired.com.mx/arq/ivan-marin-arquitectura-gana-premio/",
                text: "2019/ IMA Arquired"
            },
            {
                url: "https://mxcity.mx/2019/11/arquitecto-mexicano-ivan-marin-gana-premio-architizer/",
                text: "2019/ IMA mxcity"
            },
            {
                url: "https://obras.expansion.mx/arquitectura/2019/11/01/el-mexicano-ivan-marin-gano-un-architizer-con-un-modesto-espacio-de-lectura",
                text: "2019/ IMA obras.expansion"
            },
            {
                url: "https://coolhuntermx.com/ivan-marin-arquitectura-doho-constructivo-ganan-premio-architizer%EF%BB%BF/",
                text: "2019/ IMA Coolhuntersmx"
            },
            {
                url: "https://www.designboom.com/architecture/ivan-marin-morelos-centro-cultural-doho-constructivo-morelia-mexico-08-26-19/",
                text: "2019/ IMA Designboom"
            },
            {
                url: "https://www.metalocus.es/es/noticias/fachada-minimalista-envuelta-por-celosias-que-dan-textura-y-privacidad-wol-center-por-ivan-marin-doho-constructivo",
                text: "2019/ IMA Metalocus"
            },
            {
                url: "https://www.elnorte.com/aplicacioneslibre/preacceso/articulo/default.aspx?__rval=1&urlredirect=https://www.elnorte.com/ganan-premio-architizer/ar1823899?referer=--7d616165662f3a3a6262623b727a7a7279703b767a783a--",
                text: "2019/ IMA El Norte"
            },
            {
                url: "http://www.johannesreponen.com/journal/2019/9/16/morelia-cultural-center-2018-by-ivn-marn-doho-constructivo",
                text: "2019/ IMA Johannesreponen"
            },
            {
                url: "https://www.elsoldemorelia.com.mx/local/ivan-marin-arquitectura-doho-constructivo-gana-premio-architizer-4134565.html",
                text: "2019/ IMA El Sol de Morelia"
            }
          ]
      }
  },
  methods: {
      hoverLink() {
          this.isHover = true;
      },
      outLink() {
          this.isHover = false;
      }
  }
}
</script>

<style lang="scss" scoped>
h2 {
    margin-bottom: auto;
}

ul {
    margin: 0;
    padding-bottom: 30px;
    li {
        list-style: none;
        margin: 15px 0;
        &:last-child {
            margin-bottom: 0;
        }
        a {
            font-size: 16px;
            &.over {
                opacity: .5;
                &:hover {
                    opacity: 1;
                }
            }
        }
    }
}
</style>
