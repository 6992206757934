<template>
<div class="main-wrapper">
    <header-component />
    <slot/>
</div>
</template>

<script>
import HeaderComponent from './HeaderComponent.vue';

export default {
    components: {
        HeaderComponent
    }
}
</script>

<style lang="scss">
@import '~foundation-sites/scss/util/_util';
.horizonte {
    height: 205px;
    // background-color: red;
}

section.main-content{
    position: relative;
    & > .cell {
        display: flex;
        flex-direction: column;
        // justify-content: flex-end;
    }
}
.main-container{
    position: relative;
    // display: flex;
    // flex-direction: column;
}

.header-container {
    position: fixed;
    top: 0;
    z-index: 100;
}
@include breakpoint(medium) {
    /**/
    .main-container{
        height: 100%;
    }

    .horizonte {
        min-height: 70px;
        height: 70px;
    }
}

@include breakpoint(large) {
    .horizonte {
        height: 20%;
    }
    section.main-content{
        margin-top: auto;
        display: flex;
        // padding-bottom: 180px;

        // flex-direction: column;
        height: 80%;
        & >.cell {
            // padding-top: 7%;
        }
    }
}

</style>

<style lang="scss" scoped>
.main-wrapper {
    display: flex;
    flex-direction: column;
    .grid-container {
        width: 100%;
    }
}
</style>