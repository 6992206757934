<template>
<div class="grid-container fluid main-container">
  <div class="horizonte"></div>
  <section class="grid-x grid-margin-x main-content">
    <div class="cell large-4">
      <div v-html="$t('home')"></div>
      <img class="pt-20" src="img/ima_studio.jpg" />
      <footer-component></footer-component>
    </div>
    <div class="cell large-8 logos-col">
      <img class="dogo-logos" src="img/logos.svg" />
    </div>
  </section>
</div>
</template>

<script>
import FooterComponent from '../components/FooterComponent.vue'

export default {
  name: 'Home',
  components: {
    FooterComponent
  }
}
</script>

<style lang="scss" scoped>
@import '~foundation-sites/scss/util/_util';

section.main-content {
  img.pt-20 {
    margin-bottom: 40px;
  }
}
.logos-col{
  position: relative;
  padding-bottom: 20px;
}
.dogo-logos {
  width: 150px;
  right: 0;
}

@include breakpoint(large) {
  .dogo-logos {
    position: absolute;
    bottom: 30px;
  }
}

.medium-cell-block-y {
  padding-right: 20px;
}

</style>
