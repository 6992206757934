import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuescroll from 'vuescroll/dist/vuescroll-native';
import Vuex from 'vuex'
import axios from 'axios';
import i18n from './assets/i18n/index'


Vue.use(Vuex);

Vue.config.productionTip = false

Vue.use(vuescroll, {
  ops: {
    rail: {
      // gutterOfSide: '-100px',
    },
    bar: {
      // showDelay: 500,
      // onlyShowBarOnScroll: true,
      // keepShow: false,
      background: '#c1c1c1',
      opacity: .6,
      // hoverStyle: false,
      // specifyBorderRadius: false,
      // minSize: 0,
      // size: '6px',
      // disable: false
    }
  }
});

const store = new Vuex.Store({
  state: {
    projects: [],
    selectedProject: 0
  },
  mutations: {
    SET_PROJECTS (state, projects) {
      state.projects = projects;
    },
    SELECT_PROJECT (state, selectedProject) {
      state.selectedProject = selectedProject;
    }
  },
  actions: {
    async getProjects({ commit }) {
      try {
        const req = await axios.get('/data.json');
        commit("SET_PROJECTS", req.data)
      } catch (error) {
        console.error(error)
      }
    }
  }
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
