export default {
    langBtn: 'Read in English',
    menu: {
        office: 'Estudio',
        portfolio: 'Portafolio',
        archive: 'Plano Rojo',
        news: 'Prensa'
    },
    home: '<p>Desde la fundación de su firma en 2005, Iván Marín ha desarrollado un método de diseño que toma como base la investigación contextual, histórica y física de cada emplazamiento, que sirve como punto de partida para abordar las diferentes soluciones arquitectónicas, considerando los recursos locales, para alcanzar una nueva arquitectura vernácula fusionada con elementos contemporáneos.</p><p>Nace de un particular sistema propio de trazado a partir de los “ejes compositivos”, usando gráficas solares, ejes cardinales y el lenguaje de las figuras geométricas, generando arquitectura sensible y armoniosa con la determinación de trascender en el tiempo.</p>',
    archive: {
        title: 'PLANO ROJO Y EJES COMPOSITIVOS',
        content: 'Metodología de diseño propia, formulada a partir de los Ejes Compositivos de proyecto que tienen como base el uso de gráficas solares, los ejes cardinales, los vértices de los polígonos donde se desplantan los proyectos, y el lenguaje de las figuras geométricas, que retoman el conocimiento de culturas ancestrales como la maya, azteca y egipcia, con la certeza de que la geometría, aplicada en su totalidad en la arquitectura, genera espacios capaces de producir sensaciones de bienestar en los usuarios.'
    },
    newsTitle: 'PRENSA Y NOTICIAS',
    projects: {
        nav: {
            "next": "Siguiente",
            "prev": "Anterior"
        },
        tags: {
            "3d": "Artista 3D",
            "photo": "Fotografía"
        },
        cem: 'Ganador del premio Architizer 2019. Este pequeño centro cultural es la primera pieza de un plan de desarrollo que esta modesta escuela ha generado para mejorar el desempeño de sus alumnos y docentes. Como base de las soluciones de este plan, la dirección del plantel ha dado una importancia relevante a la arquitectura, debido a que a través de los treinta años que tiene operando ha realizado la auto construcción de múltiples edificios, todos en etapas y bajo diferentes contextos, lo que ha concluido en una multiplicidad de estilos con una gran variedad de materiales, generando un caos de usos y ambientes pocos propicios para el desarrollo de la educación.',
        evangelina: "Evangelina es un jardín de eventos ubicado al sur de la ciudad de Morelia, en una zona con una ocupación de uso mayormente habitacional, en una superficie de 804m2. El reto consistió en escapar del esquema popular y monotemático de la zona, y crear un singular espacio de contemplación donde los protagonistas fueran los pisos y muros de piedra volcánica del sitio, que llevan al visitante al interior del edificio principal, cruzando por espejos de agua iluminados, para descubrir una caja transparente, rodeada de vegetación.",
        ceconexpo: 'La remodelación del Centro de Convenciones de Morelia, Michoacán, México, es un proyecto que busca dignificar lo más valioso de la arquitectura post moderna de la ciudad, replanteando de una manera vanguardista el espacio público que lo conforma, actualizando sus capacidades para poder albergar más y mejores eventos en sus inmediaciones y con esto impulsar la capacidad económica y turística del estado.',
        'unidad-admin': '<p>Ubicado en un antiguo centro penitenciario, la Unidad Administrativa será la nueva sede de la Administración Estatal. Actualmente, el gobierno del Estado a través de este proyecto pretende eliminar el pago por concepto de rentas de espacios de oficinas, ya que no cuenta con inmuebles propios. Con este proyecto se pretende eficientar operativa y socialmente este gasto, integrando en un solo lugar varias dependencias y otros usos complementarios, generando un cambio verdadero en la ciudad y en la imagen de la función pública.</p><p>Teniendo como base de diseño público los Centros Urbanos reticulados, además de los edificios que ahí se desplantan, el proyecto integra espacios públicos libres y accesibles, concentrando así, servicios administrativos, equipamiento y otros usos mixtos, todo con la intención de mejorar la calidad en la atención, los servicios y uso de los recursos públicos. </p><p>Utilizar una ex-penitenciaría para convertirla en un nuevo centro para la Administración Pública representa metafóricamente el renacimiento, la transformación, el compromiso y la responsabilidad de un gobierno ante la sociedad.</p>',
        'casa-g': '<p>La Cocina Tradicional Mexicana es un modelo cultural completo, que comprende actividades agrarias, costumbres ancestrales, prácticas rituales, conocimientos antiguos y técnicas culinarias. Es por ello que en el año 2010 recibe el nombramiento de Patrimonio Cultural Inmaterial de la Humanidad por la UNESCO, “La cocina tradicional mexicana, cultura viva - El paradigma de Michoacán”.</p><p>La Casa de la Gastronomía de México es un proyecto que enmarca y preserva este patrimonio, es un centro atractivo, contemporáneo y dinámico, de promoción para las cocineras tradicionales, un espacio académico y de investigación culinaria, de referencia nacional e internacional.</p><p>En la cocina tradicional mexicana se observa una gran cantidad de platos, vasijas y ollas de diferentes tamaños, todos ellos de forma redonda, así como la tortilla, el sope y la corunda de ceniza. El edificio adopta una forma circular descentrada de corazón abierto, el cual albergará un gran espejo de agua en la planta baja y dos amplias rampas que llevan al primer nivel.</p>',
        'big-back-bone': '<p>Una visión apocalíptica de sitios urbanos.</p><p>El diseño de la torre está definido por una condición de antigeometría para alcanzar una alta eficiencia en rigidez y funciones de fuerza, que se levanta como una estructura muscular y cervical de la historia del ‘plan libre’ metamoderno, entrando orgánicamente desde la ciudad y situada en la tierra del antiguo ‘Campo Marte’, la principal sede militar de la nación, implicando una sustitución humanista de la idea de expansión, por nuevas formas de salvación de la raza humana. Una redefinición evolutiva e inevitable de la tipología de rascacielos. Una metáfora de la minúscula planta que emerge después del Armagedón.</p><p>A través de esta torre funciona un sistema rizomático de estructuras hídricas y biológicas que posibilita la recolección, la conducción, los usos hidropónicos, el almacenamiento para la reutilización y la infiltración de la superficie freática, por lo que la construcción ayuda en la restauración del equilibrio entre ciudades y naturaleza.</p>',
        musea: '<p>El Museo Activo de Historia de México (MUSEA), es un museo que albergará la “colección Windsor”, la cual contiene cientos de objetos, documentos históricos y colección bibliográfica que comprende distintas épocas de la historia de México. Refleja principalmente acontecimientos y personalidades del siglo XIX y principios del XX.</p><p>Se ubicará dentro de un Centro Histórico, que fue nombrado Patrimonio de la Humanidad por la UNESCO en el año de 1991, donde los mismos edificios han sido testigos y protagonistas de algunos de los eventos más significativos de la historia de este país.</p><p>El proyecto se llevará a cabo interviniendo un edificio existente, conocido popularmente como Ex-Convento Capuchinas, por haber albergado en el siglo XVIII a las monjas Capuchinas. La renovación del inmueble es necesaria a consecuencia de su envejecimiento y para adaptarlo a nuevos usos y diferentes actividades, buscando devolver a los espacios deteriorados las condiciones óptimas de calidad para los usuarios finales.</p>',
        wol: '<p>Ganador del premio RTF 2020 con sede en Nueva Delhi, dentro de la categoría arquitectura comercial, este pequeño edificio de oficinas da respuesta a las necesidades derivadas del alto nivel de globalización en el mundo, que ha generado nuevos modelos de negocios. Las oficinas virtuales están siendo bien aceptadas por profesionistas de cualquier área, empresas locales o incluso grandes empresas internacionales. </p><p>Este proyecto de forma simple y atemporal, consistió en la remodelación de una casa habitación ubicada en esquina, construida en los años 80’s, en una zona que de origen tuvo un uso completamente habitacional, y al paso del tiempo fue testigo del crecimiento y desarrollo desordenado de esta ciudad, generando un contexto inmediato a la obra sin identidad arquitectónica definida.</p>',
        'endemic-zoo': '<p>La propuesta es generar un parque endémico abierto donde, en lugar de contener y restringir, sea un atractor de vida silvestre, por medio de un ambiente botánico adecuado y de características regionales que promuevan las interacciones bióticas, mismas que evidencialmente han aportado a la teoría de evolución de las especies, no solo en patrones sino en procesos. </p><p>La arquitectura, es ahora solo un par de edificios que apenas tocan el suelo: el primero, genera un recorrido circular y panorámico a través de un museo multimedia interactivo animal y botánico y un centro de investigaciones de conservación de especies endémicas; un segundo edificio como centro de reproducción endémico con área de laboratorio y albergue temporal modulable; y un último edificio subterráneo para proyecciones 4D.</p><p>Es acerca de repensar el zoológico como un espacio de vida endémica, como sistema de comprensión y respeto a los seres vivos cuya importancia trasciende su tamaño y características estéticas. HANDLE WITH CARE.</p>',
        distrito: '<p>Distrito es un desarrollo de uso mixto el cual integra una combinación de oficinas, hospedaje, comercio, recreación y otros servicios en un mismo proyecto. Generará una mayor rentabilidad económica, aprovechando al máximo el uso del espacio, aumentando la plusvalía del terreno a través de la calidad de vida de los habitantes.</p><p>Las ciudades desde hace décadas se enfrentan a un proceso de cambio económico y social, y su desarrollo está sustentado en el sector de servicios, tales como: finanzas, educación, comercio, entretenimiento, salud, turismo y gastronomía. Hoy en día, los proyectos de gran magnitud, deben incorporar espacios comerciales con el objetivo de atender a los habitantes o usuarios de cada inmueble, generando a su vez un horario completo de ocupación.</p>',
        'hola-hospice': '<p>HOLA es una corporación sin fines de lucro de California, USA, que se ha establecido para brindar apoyo y atención directa a personas con enfermedades terminales y a sus familias. </p><p>La organización HOLA ha tenido la visión de construir un hospicio de primer mundo, que dará servicio gratuito a las personas que requieran asistencia, no importando la edad, género, nivel educativo, etc.</p><p>Un Hospicio se dedica a brindar apoyo a pacientes con enfermedades terminales y a sus seres queridos. Se enfoca en mejorar la calidad de vida y preparar al enfermo para que no sufra a medida que el final de su vida se aproxima. Un hospicio no prolonga la vida, ni intenta curar la enfermedad, tampoco apresurar la muerte; solo permite que la muerte siga su curso natural, brindando calidad de vida al paciente.</p>',
        'brazil-worldgate': '<p>Emplazado en una de las ciudades más representativas de la cultura de Brasil, WorldGate es un portal virtual que conectará a todos los países participantes permitiendo que el espíritu que se vive en la copa sea mundial, además es un centro de reunión para transmitir partidos en vivo y celebrar.</p><p>WorldGate es el área verde que recibirá a los aficionados en Río de Janeiro fungiendo como escenario de celebración. Debajo del talud podrán encontrar información y sentarse a tomar bebidas en el área de cafetería, mientras que la superficie del talud es en realidad un auditorio al aire libre para disfrutar del día y ver las transmisiones de los partidos en la pantalla inspirada en el Armadillo Brasileño, quien tiene un sorprendente sistema en su estructura y es una especie en peligro de extinción. Imitar la estructura y su perfecto movimiento envolvente genera una icónica estructura que tendrá como fin crear conciencia sobre este increíble animal y su situación.</p>',
        'aves-rapaces': '<p>El Zoológico de Morelia cuenta actualmente con un aviario, y una gran cantidad de albergues para aves.  Se intervino un nuevo albergue, creado especialmente para especies como águilas, aguilillas y halcones, mejor conocidas como aves rapaces. </p><p>A pesar de pertenecer a diferentes especies, estas aves son capaces de convivir dentro de un mismo albergue y además se ha tomado en cuenta que su comportamiento es similar en cuanto a alimentación, hábitos, y espacio necesario para desarrollarse. </p><p>Considerando el albergue existente, se realizó una transformación a partir de su espacio actual aprovechando terrenos aledaños, y los visitantes pueden tener una aproximación más real hacia estas especies.</p>',
        'albergue-osa-polar': '',
        'aak-yoga-meditación': '',
        'aak-comercial': '',
        'aak-1-2': '',
        'mz-aak': '',
        cubilu: '',
        'alstom-vestidores': '',
        'casa-cortez': '',
        bunker: '',
        'masterplan-smt': '',
        'casa-sigal': '',
        'casa-balcanes': '<p>Sumergida en un bosque de encino y pino, la casa aparece protegida por un muro de piedra laja de la región, que separa el garaje del resto de la residencia. La singularidad del lenguaje formal de esta casa tiene su inspiración en la arquitectura tradicional japonesa, diseñada en un único volumen de aparente simplicidad geométrica donde se albergan las actividades familiares, y una saliente independiente que alberga el estudio, y que a la vez se conecta con el cuerpo principal.</p><p>La vocación protectora del conjunto queda evidenciada a través del uso de la madera como material protagónico, que al interior es de pino natural, recubriendo pisos, muros y plafones, lo que genera la sensación de calidez y confort; y al exterior, cubriendo todas las fachadas y cubiertas, es tratada con una popular técnica japonesa shou sugi ban, que consiste en la quema de la capa externa dejando la madera inmune al ataque de la intemperie y las agresiones naturales.</p>',
        'wol-dos-cero': '<p>Después de ser galardonado con el premio de arquitectura RTF (Re-ThinkingTheFuture), el año 2020, con sede en Nueva Deli, India, este exitoso negocio de co-working local, da un paso más hacia su inevitable futuro de expansión. Tomando como primer paso la adquisición del edificio adyacente.</p><p>Se trata de una construcción que alojó una casa habitación en los años 80´s, en esta zona, que de origen tuvo un uso completamente habitacional, y al paso del tiempo fue testigo del crecimiento y desarrollo desordenado de esta ciudad, generando un contexto inmediato a la obra sin identidad arquitectónica definida.</p><p>En este caso, esta pequeña remodelación trata de no competir con el galardonado proyecto original, más bien, busca generar un inocente homenaje, al no intentar parecerse ni en lo formal, así como en el uso de materiales. Sin embargo, contempla un elemento observatorio, que además de servir como el principal espacio de esparcimiento y concentración del edificio, unirá de una forma muy destacada a ambos edificios que compartirán funciones en su interior.</p>'
    }
}
