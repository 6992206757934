<template>
<div class="grid-container fluid main-container">
  <div class="horizonte"></div>
  <section class="grid-x grid-margin-x main-content">
    <div class="cell large-5 xlarge-4">
      <div id="portfolio-navigation" class="show-for-medium">
        <h4 class="pro-title">
          <router-link :to="'portfolio/'+selectedData.slug"><b>{{ selectedData.name }}/</b> {{ selectedData.subtitle }}</router-link>
        </h4>
        <ul class="no-bullet">
          <li v-for="(pro, i) in projects" :key="i" :class="{selected: selectedProject == i}" @mouseover="selectProject(i)">{{ projectNumer(i) }}</li>
        </ul>
      </div>
      <footer-component class="show-for-large" />
    </div>
    <div class="cell large-7 xlarge-8 images-cell">
      <ul id="portfolio-container">
        <template v-for="(pro, i) in projects" >
          <li :class="{bottom: i % 2 == 0, selected: i == selectedProject}" :key="i">
            <p class="show-for-small-only pro-title">
               <router-link :to="'portfolio/'+pro.slug"><b>{{ pro.name }}/</b> {{ pro.subtitle }}</router-link>
            </p>
            <img @click="gotoProject(pro)" :src="'img/portfolio/'+pro.slug+'/0.jpg'">
          </li>
        </template>
      </ul>
    </div>
    <footer-component class="cell hide-for-large" />
  </section>
</div>
</template>

<script>
import FooterComponent from '../components/FooterComponent.vue';

export default {
  name: 'Portfolio',
  components: {
    FooterComponent
  },
  data() {
    return {
    };
  },
  mounted(){
    if (!this.$store.state.projects.length)
      this.$store.dispatch("getProjects");
  },
  methods: {
    gotoProject(pro) {
      this.$router.push('portfolio/'+pro.slug);
    },
    projectNumer(index){
      const i = index+1;
      return i < 10 ? '0'+i : i;
    },
    selectProject(i) {
      this.$store.commit("SELECT_PROJECT", i);
    }
  },
  computed: {
    selectedData() {
      return this.projects.length ? this.projects[this.selectedProject] : {};
    },
    projects() {
      return this.$store.state.projects; 
    },
    selectedProject() {
      return this.$store.state.selectedProject; 
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~foundation-sites/scss/util/_util';

.pro-title {
  font-size: 21px;
  color: #000;
}

section.main-content {
  padding-bottom: 0;
  position: initial;
}

#portfolio-container {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  li{
    width: 100%;
    display: block;
    align-items: baseline;
    justify-content: flex-end;
    margin-bottom: 50px;
    img {
      cursor: pointer;

    }
    &.bottom {
      align-items: flex-end;
    }
  }
}

#portfolio-navigation {
  // margin: auto 0;
  // padding-bottom: 30%;
  margin-bottom: auto;;
  h4 {
    font-weight: normal;
    // letter-spacing: 2px;
  }
  ul {
    li {
      font-size: 16px;
      color: black;
      display: inline-block;
      margin: 0;
      margin-right: 15px;
      cursor: pointer;

      &.selected {
        border-bottom: 1px solid black;
      }

      &:last-child {
        margin-right: 0;
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }
}

@include breakpoint(medium) {
  
  #portfolio-container {
    background-color: #fff5ec;
    height: 100%;
    padding-bottom: 0px;

    li {
      display: none;
      height: 100%;
      &.selected {
        display: flex;
      }
    }

    img{
      max-height: 100%;
      max-width: 75%;
      /* display: none; */
    }
  }
}

@include breakpoint(medium only) {
  section.main-content {
    height: 80%;
  }
  .images-cell {
    height: 65%;
  }
}

@include breakpoint(large) {
  .images-cell {
    height: 100%;
  }
}
</style>