<template>
<div class="grid-container fluid main-container">
  <div class="horizonte"></div>
  <section class="grid-x grid-padding-x main-content">
    <div class="cell large-5 xlarge-4">
      <!-- <div id="portfolio-inner-navigation" class="grid-x grid-padding-x">
        <div class="cell large-5 xlarge-4"> -->
          <h4><b>{{ project.name }}/</b> {{ project.subtitle }}/ {{ project.size }}</h4>
          <ul class="project-tags" >
            <li v-for="(tagVal, tagName) in project.tags" :key="tagName">{{ $t('projects.tags.'+tagName) }}: {{ tagVal }}</li>
          </ul>
          <!-- <router-link to="/portfolio" class="navigation-btn back">← Regresar</router-link> -->
          <p v-html="$t('projects.'+project.slug)"></p>
          <p>
            <router-link v-if="prevProject" :to="prevProject.slug" class="navigation-btn prev"> &lt; {{ $t('projects.nav.prev') }}</router-link>
            <router-link v-if="nextProject" :to="nextProject.slug" class="navigation-btn next">{{ $t('projects.nav.next') }} &gt;</router-link>
          </p>
        <!-- </div>
      </div> -->
      <div id="footer-wrapper" class="grid-x grid-padding-x show-for-large">
        <div class="cell large-5 xlarge-4">
          <footer-component />
        </div>
      </div>
    </div>
    <div class="cell large-7 xlarge-8">
        <div class="grid-x grid-margin-x imgs-container">
          <div v-for="img in imgs" :key="img.src" :class="['cell', 'medium-'+img.cells]" @click="show(img.i)" :style="{'background-image': 'url(\''+img.src+'\')' }">
            <img :src="img.src" />
          </div>
        </div>
    </div>
  </section>
  <vue-easy-lightbox
      escDisabled
      moveDisabled
      :visible="visible"
      :imgs="imgs"
      :index="index"
      @hide="handleHide"
    ></vue-easy-lightbox>
  <footer-component class="hide-for-large" />
</div>
</template>

<script>
import FooterComponent from '../components/FooterComponent.vue';
import VueEasyLightbox from 'vue-easy-lightbox'

export default {
  name: 'PortfolioInner',
  components: {
    FooterComponent,
    VueEasyLightbox
  },
  props: ['slug'],
  data() {
    return {
      visible: false,
      index: 0,
      project: {},
      imgs: []
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      this.slug = to.params.slug;
      this.loadProjectInfo();
    }
  },
  async mounted(){
    if (!this.$store.state.projects.length)
      await this.$store.dispatch("getProjects");
    this.loadProjectInfo();
  },
  methods: {
    loadProjectInfo() {
      const projectIndex = this.projects.findIndex(p => p.slug == this.slug);
      if (projectIndex != -1) {

        this.$store.commit("SELECT_PROJECT", projectIndex);

        this.project = this.projects[projectIndex];

        this.imgs = [];
        
        for (let i = 0; i < this.project.imgs;) {

          const tcols = Math.random() > .6 ? i == this.project.imgs - 1 ? 1 : 2 : 1;
          const imgCols = 12 / tcols;

          for (let j = 0; j < tcols; j++) {
            if (i < this.project.imgs)
              this.imgs.push({
                i: i,
                src: '/img/portfolio/'+this.project.slug+'/'+(i+1)+'.jpg',
                cells: imgCols
              });
            i++;
          }
        }
      } else {
        //Go to 404
      }
    },
    projectNumer(index){
      const i = index+1;
      return i < 10 ? '0'+i : i;
    },
    show(index) {
      this.index = index;
      this.visible = true
    },
    handleHide() {
      this.visible = false
    }
  },
  computed: {
    nextProject() {
      if (this.projectIndex+1 < this.projects.length){
        return this.projects[this.projectIndex+1];
      }
      return null;
    },
    prevProject() {
      if (this.projectIndex-1 > -1){
        return this.projects[this.projectIndex-1];
      }
      return null;
    },
    projects() {
      return this.$store.state.projects; 
    },
    projectIndex() {
      return this.$store.state.selectedProject; 
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~foundation-sites/scss/util/_util';
.project-tags {
  list-style: none;
  margin: 0;
  margin-bottom: 20px;
  li {
    font-size: 21px;
    color: #000;
  }
}

h4 {
  font-weight: normal;
  margin: 0;
}
.navigation-btn {
  font-size: 16px;
  color: black;
  &.next {
    float: right;
  }
}
.imgs-container {
  img {
    opacity: 0;
  }
  .cell {
    margin-bottom: 20px;
    background-size: cover;
    background-position: center;
    cursor: pointer;
  }
}

.main-content > .cell{
  position: relative;
}

@include breakpoint(large) {
  #footer-wrapper {
    width: 97.615%;
    bottom: 0px;
  }

  .imgs-container .cell {
    margin-bottom: 30px;
  }
  #portfolio-inner-navigation{
    top: 20%;
  }
  #portfolio-inner-navigation, #footer-wrapper {
    // position: fixed;
    // padding-right: 75px;
    position: absolute;
    .cell {
      width: 100%;
    }
  }

}


</style>