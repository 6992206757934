<template>
  <component :is="layout">
        <router-view :layout.sync="layout"></router-view>
  </component>
</template>

<script>
import DefaultLayout from './components/DefaultLayout.vue'

export default {
  name: 'App',
  components: {},
  data() {
    return {
      layout: DefaultLayout
    }
  },
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;600;700&display=swap');
@import './assets/_settings.scss';
@import "~foundation-sites/scss/foundation";

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;

// Helpers
@include foundation-float-classes;
// @include foundation-flex-classes;
@include foundation-visibility-classes;


h1, h2, h3, h4 {
    font-size: 21px;
    font-weight: 600;
    color: #000;
}

body {
    height: 100%;
    font-size: 12px;
    color: #7d7d7d;
}

body, a, a:active, a:visited {
    font-family: 'Roboto Mono', monospace;
}

html, body, .main-wrapper {
    height: 100%;
}

a {
  padding-bottom: 2px;
  border-bottom: 1px solid white;
  color: black;
  &:hover {
    color: black;
    border-bottom: 1px solid black;
  }
}

.pt-40 {
    margin-top: 40px;
}

.pt-20 {
    margin-top: 20px;
}
</style>