export default {
    langBtn: 'Leer en Español',
    menu: {
        office: 'Studio',
        portfolio: 'Portfolio',
        archive: 'Red Plan',
        news: 'Press'
    },
    home: '<p>Since the founding of his firm in 2005, Iván Marín has developed a design method based on the contextual, historical and physical research of each site, which serves as a starting point to address the different architectural solutions, considering local resources, to achieve a new vernacular architecture fused with contemporary elements.</p><p>It is born from a particular system of tracing based on the "compositional axes", using solar graphs, cardinal axes and the language of geometric figures, generating sensitive and harmonious architecture with the determination to transcend time.</p>',
    archive: {
        title: 'RED PLAN AND COMPOSITIONAL AXES',
        content: 'Own design methodology, formulated from the Composite Axes of the project, that are based on the use of solar graphs, cardinal axes, the vertices of the polygons where the projects are located, and the language of geometric figures, which retake the knowledge of ancestral cultures such as the Mayan, Aztec and Egyptian, with the certainty that geometry, applied in its entirety in architecture, generates spaces capable of producing well-being feelings in its users.'
    },
    newsTitle: 'PRESS',
    projects: {
        nav: {
            "next": "Next",
            "prev": "Back"
        },
        tags: {
            "3d": "3D Artist",
            "photo": "Photography"
        },
        cem: 'Winner of the 2019 Architizer Award. This small cultural center is the first piece of a development plan that this modest school has generated to help improve the performance of its students and teachers. As part of the solutions, the campus management has now given a relevant importance to architecture, because through the thirty years of its operation it has been built by self-construction of multiple buildings, all in different stages and contexts, that has led to a multiplicity of styles with an extensive variety of materials, creating chaos of uses and environments that do not point to the education promotion.',
        evangelina: 'Evangelina is a garden for events located in the south of the city of Morelia, in an area with an occupation of mostly residential use, in an area of 804 sqm. The main challenge was to escape the monothematic and popular scheme of the area and create a singular contemplation space where the protagonists are the volcanic stone floors and walls of the site, which take the visitor inside the main building crossing by illuminated reflecting pools, to discover a see-through box, surrounded by nature.',
        ceconexpo: 'The remodeling of the Convention Center at Morelia, Michoacán, Mexico, is a project that seeks to dignify the most valuable of the post-modern architecture of the city, rethinking in an avant-garde way the public space that shapes it, updating its capacities to be able to house more and better events in its surroundings and with this boost the economic and tourist capacity of the state.',
        'unidad-admin': '<p>Located in a former Penitentiary center, the Administrative Unit will be the new headquarters of the State Administration. Currently, the State government, through this project, intends to eliminate the payment for rents of office spaces, since it does not have its own properties. The aim of this project is to make this expenditure operational and socially efficient, integrating in a single place several dependencies and other complementary uses, generating a true change in the city and in the image of the public administration.</p><p>Based on the public design of the reticulated Urban Centers and the buildings that are located there, the project integrates free and accessible public spaces, thus concentrating administrative services, equipment and other mixed uses, all with the intention of improving quality in care, services and use of public resources.</p><p>Using a former penitentiary to turn it into a new center for the Public Administration metaphorically represents the rebirth, transformation, commitment and responsibility of a government to society.</p>',
        'casa-g': '<p>Traditional Mexican Cuisine is a complete cultural model, which includes agricultural activities, ancestral customs, ritual practices, ancient knowledge and culinary techniques. That is why in 2010 it received the appointment of Intangible Cultural Heritage of Humanity by UNESCO, "Traditional Mexican cuisine, living culture - The paradigm of Michoacán."</p><p>The House of Gastronomy of Mexico is a project that frames and preserves this heritage, it is an attractive, contemporary and dynamic center for the promotion of traditional cooks, an academic and culinary research space, of national and international reference.</p><p>In traditional Mexican cuisine, a large number of plates, vessels and pots of different sizes are observed, all of them round in shape, as well as the tortilla, the sope and the ash corunda. The building adopts an off-center circular shape with an open heart, which will house a large reflecting pool on the ground floor and two wide ramps that lead to the first level.</p>',
        'big-back-bone': "<p>An apocalyptic vision of urban sites.</p><p>The tower design is defined by an antigeometry condition to achieve high efficiency in stiffness and strength functions, which stands as a muscular and cervical structure of the history of the metamodern 'free plan', organically entering from the city and located in the land of the former 'Campo Marte', the nation's main military headquarters, implying a humanistic replacement of the idea of expansion, with new ways of saving the human race. An evolutionary and inevitable redefinition of the skyscraper typology. A metaphor of the tiny plant that emerges after Armageddon.</p><p>Through this tower there is a rhizomatic system of water and biological structures that allows the collection, conduction, hydroponic uses, storage for reuse and infiltration of the phreatic surface, so the construction helps in the restoration of balance between cities and nature.</p>",
        musea: '<p>Active Museum of the History of Mexico (MUSEA), is a museum that houses the "Windsor collection", which contains hundreds of objects, historical documents and bibliographic collections that include different periods in the history of Mexico. It mainly reflects events and personalities </p><p>To be located within an Historic Center, which was named a World Heritage Site by Unesco in 1991, where the same buildings have been witnesses and protagonists of some of the most significant events in the history of this country.</p><p>The project will be executed by remodeling an existing building, popularly known as the Capuchin Ex-Convent, for having housed the Capuchin nuns in the 18th century. The renovation of the property is necessary as a result of its aging and to adapt it to new uses and different activities, seeking to return to the deteriorated spaces the optimal quality conditions for the end users.</p>',
        wol: "<p>Winner of the RTF 2020 award based in New Delhi, within the commercial architecture category, this small office building responds to the needs derived from the high level world globalization, which has generated new business models. Virtual offices are being well accepted by professionals from any area, local or even large international companies.</p><p>This simple and timeless shaped project consisted in the remodeling of a corner house, built in the 80's, in an area that originally had a completely residential use, and over time witnessed the growth and disorderly development of this city, generating an immediate context to the work without a defined architectural identity.</p>",
        'endemic-zoo': '<p>The proposal is to create an open endemic park where, instead of containing and restricting wildlife, it attracts it, through an adequate botanical environment and regional characteristics that encourages biotic interactions, which have clearly contributed to the theory of the evolution of species, not only in patterns but in processes.</p><p>The architecture is now only a couple of buildings that barely touch the ground: the first one, generates a circular and panoramic tour through an interactive multimedia animal and botanical museum and a research center for the conservation of endemic species; a second building as an endemic breeding center with laboratory area and modular temporary shelter; and finally an underground building for 4D projections.</p><p>It is about rethinking the zoo as an endemic living space, as a system of understanding and respecting other living beings whose importance transcends their size and aesthetic appearances. HANDLE WITH CARE.</p>',
        distrito: '<p>Distrito is a mixed-use development which integrates a combination of offices, lodging, commerce, recreation and other services in the same project. It will generate greater economic profitability, making the most of the use of space, increasing the capital gain of the land through the quality of life of the inhabitants.</p><p>Cities for decades have faced a process of economic and social change, and their development is supported by the service sector, such as: finance, education, commerce, entertainment, health, tourism and gastronomy. Today, large-scale projects must incorporate commercial spaces in order to serve the inhabitants or users of each property, generating in turn a full occupancy schedule.</p>',
        'hola-hospice': '<p>HOLA is a California, USA, non-profit corporation, established to provide direct support and care to terminally ill people and their families.</p><p>The HOLA organization has had the vision of building a first-world hospice, which will provide free service to people who require assistance, regardless of age, gender, educational level, etc.</p><p>A Hospice is dedicated to providing support to terminally ill patients and their loved ones. It focuses on improving the quality of life and preparing the sick so that they do not suffer as the end of their life approaches. A hospice does not prolong life, nor does it try to cure disease, nor does it hasten death; it only allows death to follow its natural course, providing quality of life to the patient.</p>',
        'brazil-worldgate': '<p>Located in one of the most representative cities within the Brazilian culture, WorldGate is a virtual gateway that will connect all of the participant countries by placing one satellite in the most important square of each one, allowing the world cup’s spirit to flourish as a worldwide one. Also, it is a forum that will serve to broadcast live matches as well as to host celebrations.</p><p>WorldGate is the green area in Rio de Janeiro that will welcome the fans while serving as a celebration spot. Under its slope, there is an information and coffee area. The main surface is actually an outdoor auditorium ideal to spend the day and to watch the games on the big screen inspired by the Brazilian Armadillo, an endangered species who has an astonishing body system. The imitation of the armadillo’s structure and motion will serve to generate an iconic building that will have as its main aim to create awareness of this incredible animal and of its current situation.</p>',
        'aves-rapaces': '<p>Currently the Morelia Zoo has an aviary, and a large number of birdhouses. It has intervened a new shelter, created especially for species such as eagles and hawks, better known as raptors.</p><p>Despite belonging to different species, these birds are able to coexist within the same shelter and it has also been taken into account that their behavior is similar in terms of food, habits, and the space necessary to develop. </p><p>Considering the existing shelter, there happened a transformation from its current space taking advantage of neighboring lands, visitors will be able to have a more realistic approach towards these species.</p>',
        'albergue-osa-polar': '<p>Throughout history, human beings have discovered the importance of establishing a balance between themselves and the environment in which they develop as a necessity. There are various specialized areas that have been involved in the analysis of this problem and in their attempt to carry out this coexistence, they have developed various proposals to achieve this objective. Faced with this need, architecture has taken sides in the search for optimal solutions for the creation of spaces worthy of species, and that at the same time help to form a social conscience. Among the different options, the creation of ecological reserves plays an important role in the preservation of flora and fauna.</p><p>In particular, the city of Morelia has a 25-hectare zoo that houses 420 species. And its objective is to improve the conditions for the development of animals in captivity. One of the most attractive species that required a prompt solution to improve its environment was “Yupik” the polar bear. The intention of the project was to create an ecosystem that is as close as possible to its natural habitat and to generate interest in visitors to take care of the environment in which it develops, starting by inducing them to an indirect coexistence between human beings and the environment.</p>',
        'aak-yoga-meditación': '',
        'aak-comercial': '',
        'aak-1-2': '',
        'mz-aak': '',
        cubilu: '',
        'alstom-vestidores': '',
        'casa-cortez': '',
        bunker: 'Located in a land next to the local industrial city, with an area of 100m2, this housing-workshop has the necessary spaces for work and rest within the same loft, where the user, industrial engineer and lover of extreme sports, can comfortably take refuge in this design that has no connection with the outside as a fortification, which gives it its name.',
        'masterplan-smt': '',
        'casa-sigal': '',
        'casa-balcanes': '<p>Immersed in a pine and oak forest, the house appears protected by a wall of flagstone from the region, which separates the garage from the rest of the residence. The uniqueness of the formal language of this house is inspired by traditional Japanese architecture, designed in a single volume of apparent geometric simplicity where family activities are housed, and an independent projection that houses the study, which at the same time connects with the main body.</p><p>The protective vocation of the complex is evidenced through the use of wood as the main material, which in the inside is natural pine, covering floors, walls and ceilings, that generates a feeling of warmth and comfort; and on the outside, covering all the facades and roofs, it is treated with a popular Japanese technique, shou sugi ban, which consists of burning the outer layer, leaving the wood immune to weathering and natural aggressions.</p>',
        'wol-dos-cero': '<p>After receiving the RTF (Re-ThinkingTheFuture) Architecture Award, 2020, based in New Delhi, India, this successful local co-working business, takes another step towards its inevitable future of expansion. The first step was the acquisition of the adjacent building.</p><p>This is a building that housed a dwelling in the 80\'s, in this area, which originally had a completely residential use, and over time witnessed the growth and disorderly development of this city, generating an immediate context to the site without a defined architectural identity.</p><p>In this case, this small remodeling tries not to compete with the award-winning original project, but rather, seeks to generate an innocent homage, by not trying to resemble it in the formal aspect, as well as in the use of materials. However, it contemplates an observatory element, which in addition to serving as the main space for recreation and concentration of the building, will unite in a very prominent way both buildings that will share functions in its interior.</p>'
    },
}
