<template>
<div class="grid-container fluid main-container">
  <div class="horizonte"></div>
  <section class="grid-x grid-padding-x main-content">
    <div class="cell large-5 xlarge-4">
      <!-- <div id="portfolio-inner-navigation" class="grid-x grid-padding-x">
        <div class="cell large-5 xlarge-4"> -->
          <h4>{{ $t('archive.title') }}</h4>
          <p> {{ $t('archive.content') }} </p>
        <!-- </div>
      </div> -->
      <div id="footer-wrapper" class="grid-x grid-padding-x show-for-large">
        <div class="cell large-5 xlarge-4">
          <footer-component />
        </div>
      </div>
    </div>
    <div class="cell large-7 xlarge-8">
        <div class="grid-x grid-margin-x imgs-container">
          <div v-for="img in imgs" :key="img.src" :class="['cell', 'medium-'+img.cells]" @click="show(img.i)" :style="{'background-image': 'url(\''+img.src+'\')' }">
            <img :src="img.src" />
          </div>
        </div>
    </div>
  </section>
  <vue-easy-lightbox
      escDisabled
      moveDisabled
      :visible="visible"
      :imgs="imgs"
      :index="index"
      @hide="handleHide"
    ></vue-easy-lightbox>
    <footer-component class="hide-for-large" />
</div>
</template>

<script>
import FooterComponent from '../components/FooterComponent.vue';
import VueEasyLightbox from 'vue-easy-lightbox'

export default {
  name: 'Archive',
  components: {
    FooterComponent,
    VueEasyLightbox
  },
  props: ['slug'],
  data() {
    return {
      visible: false,
      index: 0,
      project: {},
      imgs: []
    };
  },
  async mounted(){
    if (!this.$store.state.projects.length)
      await this.$store.dispatch("getProjects");
    this.loadProjectInfo();
  },
  methods: {
    loadProjectInfo() {

        const imgsNum = 9;

        this.imgs = [];
        
        for (let i = 0; i < imgsNum;) {

          const tcols = Math.random() > .6 ? i == imgsNum - 1 ? 1 : 2 : 1;
          const imgCols = 12 / tcols;

          for (let j = 0; j < tcols; j++) {
            if (i < imgsNum)
              this.imgs.push({
                i: i,
                src: 'img/plano_rojo/'+(i+1)+'.jpg',
                cells: imgCols
              });
            i++;
          }
        }
    },
    
    show(index) {
      this.index = index;
      this.visible = true
    },
    handleHide() {
      this.visible = false
    }
  },
  computed: {
  }
}
</script>

<style lang="scss" scoped>
@import '~foundation-sites/scss/util/_util';
.navigation-btn {
  font-size: 16px;
  color: black;
  &.next {
    float: right;
  }
}
.imgs-container {
  img {
    opacity: 0;
  }
  .cell {
    margin-bottom: 20px;
    background-size: cover;
    background-position: center;
    cursor: pointer;
  }
}

// section.main-content footer.contact {
//   position: fixed;
//   bottom: 0px;
// }

.main-content > .cell{
  position: relative;
}

@include breakpoint(large) {
  
  .imgs-container .cell {
    margin-bottom: 30px;
  }
  #portfolio-inner-navigation{
    top: 20%;
  }

  #footer-wrapper {
    width: 97.615%;
    bottom: 0px;
  }

  #portfolio-inner-navigation, #footer-wrapper {
    position: absolute;
    .cell {
      width: 100%;
    }
  }
}


</style>