<template>
<header class="grid-container fluid header-container">
    <nav class="grid-x grid-padding-x top-header">
        <h1 class="cell medium-4">
            <img src="/img/logo_ima.svg" alt="IVÁN MARÍN ARQUITECTURA Logo" />
            IVÁN MARÍN ARQUITECTURA
        </h1>
        <div class="cell small-12 medium-2"><router-link to="/" exact>{{ $t("menu.office") }}</router-link></div>
        <div class="cell small-12 medium-2"><router-link to="/portfolio">{{ $t("menu.portfolio") }}</router-link></div>
        <div class="cell small-12 medium-2"><router-link to="/archive">{{ $t("menu.archive") }}</router-link></div>
        <div class="cell small-12 medium-2"><router-link to="/news">{{ $t("menu.news") }}</router-link></div>
        <!-- <router-link class="cell small-3 medium-2" to="/">Leer en Español</router-link> -->
    </nav>
</header>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>
@import '~foundation-sites/scss/util/_util';

header {
    // opacity: .5;
}

.top-header{
    padding-top: 20px;
    background-color: #fff;
    padding-bottom: 20px;

    // position: fixed;
    // width: 100%;
}

h1 {
    height: 30px;
    font-size: 0;
    line-height: 27px;
    margin-bottom: 15px;
}

img {
    width: 100%;
    max-width: 300px;
    display: inline;
    vertical-align: middle;
}
nav {
    // text-align: center;

    a {
        font-size: 16px;
        color: #000;
        padding-top: 10px;
        white-space: nowrap;
        text-transform: uppercase;
        &.selected {
            border-bottom: 1px solid black;
        }
    }
    & > * {
        line-height: 30px;
        max-height: 30px;
        // margin-bottom: 0px;
    }
}
@include breakpoint(medium only) {
    // h1 {
    //     margin-right: 10px
    // }
    // div.cell {
    //     width: auto;
    //     padding-left: 0.7rem;
    //     padding-right: 0.1rem;
    // }
}
@include breakpoint(medium) {
    nav {
        text-align: left;
        a {
            padding-top: 0;
        }
    }
    h1 {
        margin-bottom: 0px;
    }
}

</style>